.CalloutBubble {
  transform: translateX(-75%);
  animation: bob 1.5s ease-in-out 0s infinite alternate-reverse both;
}

@keyframes bob {
  from {
    transform: translateX(-75%);
  }

  to {
    transform: translateX(-100%);
  }
}

.CalloutBubble .ant-tooltip-inner {
  background-color: rgb(255, 133, 192, 0.8);
  border-radius: 15px;
}

.CalloutBubble .ant-tooltip-arrow {
  display: none;
}
