body {
  background-color: #f6f8fa !important;
}

pre {
  color: #fff;
  margin-bottom: 1.75rem;
  border-radius: 5px;
  background: #011627;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  padding: 0.6rem;
  font-feature-settings: normal;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  margin-bottom: 0;
  tab-size: 4;
}

.box-shadow-emphasize {
  -webkit-animation: add-box-shadow 3s ease-in-out 0s 1 alternate;
  animation: add-box-shadow 3s ease-in-out 0s 1 alternate-reverse forwards;
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}

@-webkit-keyframes add-box-shadow {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  100% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
    box-shadow: 1px 1px #53a7ea, 2px 2px #53a7ea, 3px 3px #53a7ea !important;
  }
}
@keyframes add-box-shadow {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    box-shadow: 0px 0px #53a7ea, 0px 0px #53a7ea, 0px 0px #53a7ea !important;
  }
  100% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
    box-shadow: 1px 1px #53a7ea, 2px 2px #53a7ea, 3px 3px #53a7ea !important;
  }
}
